import { BoldText } from "../../../../src/components/bold-text/bold-text";
import { InlineCode } from "../../../../src/components/inline-code/inline-code";
import { ItalicText } from "../../../../src/components/italic-text/italic-text";
import { PostUpdateTitle } from "../../../../src/components/post-update-title/post-update-title";
import * as React from 'react';
export default {
  BoldText,
  InlineCode,
  ItalicText,
  PostUpdateTitle,
  React
};