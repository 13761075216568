module.exports = [{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/node_modules/gatsby-remark-images","id":"0a918d20-5ad0-5db2-89e6-d292a6d967f4","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/node_modules/gatsby-remark-highlight-code","id":"fc7c0569-25d8-5346-b81e-1ab32f999131","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[],"terminal":"carbon","theme":"dracula"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{"posts":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx"},"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
