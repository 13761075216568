import * as React from 'react';

import { title } from './post-update-title.module.css';

export const PostUpdateTitle: React.FunctionComponent = (props) => (
    <h2 className={title}>
        <span aria-hidden="true">&raquo;&nbsp;</span>
        {props.children}
    </h2>
);
