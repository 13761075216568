import * as React from 'react';

import { bold } from './bold-text.module.css';

interface BoldTextProps {
    isParagraph?: boolean;
}

export const BoldText: React.FunctionComponent<BoldTextProps> = (props) => {
    return props.isParagraph ? (
        <p className={bold}>
            {props.children}
        </p>
    ) : (
        <span className={bold}>
            {props.children}
        </span>
    );
};
