import * as React from 'react';

import { c } from './inline-code.module.css';

/**
 * Special highlighting of inline code. Text has reduced font-size by default.
 */
export const InlineCode: React.FunctionComponent = (props) => (
    <code className={c} lang="en">
        {props.children}
    </code>
);
