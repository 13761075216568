exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-posts-2022-3-underrated-awesome-html-elements-de-mdx": () => import("./../../../src/posts/2022/3-underrated-awesome-html-elements.de.mdx" /* webpackChunkName: "component---src-posts-2022-3-underrated-awesome-html-elements-de-mdx" */),
  "component---src-posts-2022-3-underrated-awesome-html-elements-en-mdx": () => import("./../../../src/posts/2022/3-underrated-awesome-html-elements.en.mdx" /* webpackChunkName: "component---src-posts-2022-3-underrated-awesome-html-elements-en-mdx" */),
  "component---src-posts-2022-3-ways-web-devs-benefit-accessibility-de-mdx": () => import("./../../../src/posts/2022/3-ways-web-devs-benefit-accessibility.de.mdx" /* webpackChunkName: "component---src-posts-2022-3-ways-web-devs-benefit-accessibility-de-mdx" */),
  "component---src-posts-2022-3-ways-web-devs-benefit-accessibility-en-mdx": () => import("./../../../src/posts/2022/3-ways-web-devs-benefit-accessibility.en.mdx" /* webpackChunkName: "component---src-posts-2022-3-ways-web-devs-benefit-accessibility-en-mdx" */),
  "component---src-posts-2022-a-11-y-audits-screenreader-de-mdx": () => import("./../../../src/posts/2022/a11y-audits-screenreader.de.mdx" /* webpackChunkName: "component---src-posts-2022-a-11-y-audits-screenreader-de-mdx" */),
  "component---src-posts-2022-a-11-y-audits-screenreader-en-mdx": () => import("./../../../src/posts/2022/a11y-audits-screenreader.en.mdx" /* webpackChunkName: "component---src-posts-2022-a-11-y-audits-screenreader-en-mdx" */),
  "component---src-posts-2022-accessible-file-input-de-mdx": () => import("./../../../src/posts/2022/accessible-file-input.de.mdx" /* webpackChunkName: "component---src-posts-2022-accessible-file-input-de-mdx" */),
  "component---src-posts-2022-accessible-file-input-en-mdx": () => import("./../../../src/posts/2022/accessible-file-input.en.mdx" /* webpackChunkName: "component---src-posts-2022-accessible-file-input-en-mdx" */),
  "component---src-posts-2022-accessible-interactive-charts-de-mdx": () => import("./../../../src/posts/2022/accessible-interactive-charts.de.mdx" /* webpackChunkName: "component---src-posts-2022-accessible-interactive-charts-de-mdx" */),
  "component---src-posts-2022-accessible-interactive-charts-en-mdx": () => import("./../../../src/posts/2022/accessible-interactive-charts.en.mdx" /* webpackChunkName: "component---src-posts-2022-accessible-interactive-charts-en-mdx" */),
  "component---src-posts-2022-accessible-toggle-button-de-mdx": () => import("./../../../src/posts/2022/accessible-toggle-button.de.mdx" /* webpackChunkName: "component---src-posts-2022-accessible-toggle-button-de-mdx" */),
  "component---src-posts-2022-accessible-toggle-button-en-mdx": () => import("./../../../src/posts/2022/accessible-toggle-button.en.mdx" /* webpackChunkName: "component---src-posts-2022-accessible-toggle-button-en-mdx" */),
  "component---src-posts-2022-cascade-layers-in-angular-de-mdx": () => import("./../../../src/posts/2022/cascade-layers-in-angular.de.mdx" /* webpackChunkName: "component---src-posts-2022-cascade-layers-in-angular-de-mdx" */),
  "component---src-posts-2022-cascade-layers-in-angular-en-mdx": () => import("./../../../src/posts/2022/cascade-layers-in-angular.en.mdx" /* webpackChunkName: "component---src-posts-2022-cascade-layers-in-angular-en-mdx" */),
  "component---src-posts-2022-chatgpt-accessibility-myths-de-mdx": () => import("./../../../src/posts/2022/chatgpt-accessibility-myths.de.mdx" /* webpackChunkName: "component---src-posts-2022-chatgpt-accessibility-myths-de-mdx" */),
  "component---src-posts-2022-chatgpt-accessibility-myths-en-mdx": () => import("./../../../src/posts/2022/chatgpt-accessibility-myths.en.mdx" /* webpackChunkName: "component---src-posts-2022-chatgpt-accessibility-myths-en-mdx" */),
  "component---src-posts-2022-common-aria-mistakes-de-mdx": () => import("./../../../src/posts/2022/common-aria-mistakes.de.mdx" /* webpackChunkName: "component---src-posts-2022-common-aria-mistakes-de-mdx" */),
  "component---src-posts-2022-common-aria-mistakes-en-mdx": () => import("./../../../src/posts/2022/common-aria-mistakes.en.mdx" /* webpackChunkName: "component---src-posts-2022-common-aria-mistakes-en-mdx" */),
  "component---src-posts-2022-develop-keen-sense-of-accessibility-de-mdx": () => import("./../../../src/posts/2022/develop-keen-sense-of-accessibility.de.mdx" /* webpackChunkName: "component---src-posts-2022-develop-keen-sense-of-accessibility-de-mdx" */),
  "component---src-posts-2022-develop-keen-sense-of-accessibility-en-mdx": () => import("./../../../src/posts/2022/develop-keen-sense-of-accessibility.en.mdx" /* webpackChunkName: "component---src-posts-2022-develop-keen-sense-of-accessibility-en-mdx" */),
  "component---src-posts-2022-forced-colors-mode-de-mdx": () => import("./../../../src/posts/2022/forced-colors-mode.de.mdx" /* webpackChunkName: "component---src-posts-2022-forced-colors-mode-de-mdx" */),
  "component---src-posts-2022-forced-colors-mode-en-mdx": () => import("./../../../src/posts/2022/forced-colors-mode.en.mdx" /* webpackChunkName: "component---src-posts-2022-forced-colors-mode-en-mdx" */),
  "component---src-posts-2022-get-started-with-a-11-y-de-mdx": () => import("./../../../src/posts/2022/get-started-with-a11y.de.mdx" /* webpackChunkName: "component---src-posts-2022-get-started-with-a-11-y-de-mdx" */),
  "component---src-posts-2022-get-started-with-a-11-y-en-mdx": () => import("./../../../src/posts/2022/get-started-with-a11y.en.mdx" /* webpackChunkName: "component---src-posts-2022-get-started-with-a-11-y-en-mdx" */),
  "component---src-posts-2022-highly-visible-keyboard-focus-de-mdx": () => import("./../../../src/posts/2022/highly-visible-keyboard-focus.de.mdx" /* webpackChunkName: "component---src-posts-2022-highly-visible-keyboard-focus-de-mdx" */),
  "component---src-posts-2022-highly-visible-keyboard-focus-en-mdx": () => import("./../../../src/posts/2022/highly-visible-keyboard-focus.en.mdx" /* webpackChunkName: "component---src-posts-2022-highly-visible-keyboard-focus-en-mdx" */),
  "component---src-posts-2022-how-not-to-use-images-de-mdx": () => import("./../../../src/posts/2022/how-not-to-use-images.de.mdx" /* webpackChunkName: "component---src-posts-2022-how-not-to-use-images-de-mdx" */),
  "component---src-posts-2022-how-not-to-use-images-en-mdx": () => import("./../../../src/posts/2022/how-not-to-use-images.en.mdx" /* webpackChunkName: "component---src-posts-2022-how-not-to-use-images-en-mdx" */),
  "component---src-posts-2022-lights-off-how-blind-users-navigate-de-mdx": () => import("./../../../src/posts/2022/lights-off-how-blind-users-navigate.de.mdx" /* webpackChunkName: "component---src-posts-2022-lights-off-how-blind-users-navigate-de-mdx" */),
  "component---src-posts-2022-lights-off-how-blind-users-navigate-en-mdx": () => import("./../../../src/posts/2022/lights-off-how-blind-users-navigate.en.mdx" /* webpackChunkName: "component---src-posts-2022-lights-off-how-blind-users-navigate-en-mdx" */),
  "component---src-posts-2022-looking-back-at-2022-de-mdx": () => import("./../../../src/posts/2022/looking-back-at-2022.de.mdx" /* webpackChunkName: "component---src-posts-2022-looking-back-at-2022-de-mdx" */),
  "component---src-posts-2022-looking-back-at-2022-en-mdx": () => import("./../../../src/posts/2022/looking-back-at-2022.en.mdx" /* webpackChunkName: "component---src-posts-2022-looking-back-at-2022-en-mdx" */),
  "component---src-posts-2022-native-dialog-element-de-mdx": () => import("./../../../src/posts/2022/native-dialog-element.de.mdx" /* webpackChunkName: "component---src-posts-2022-native-dialog-element-de-mdx" */),
  "component---src-posts-2022-native-dialog-element-en-mdx": () => import("./../../../src/posts/2022/native-dialog-element.en.mdx" /* webpackChunkName: "component---src-posts-2022-native-dialog-element-en-mdx" */),
  "component---src-posts-2022-spell-it-out-screen-reader-de-mdx": () => import("./../../../src/posts/2022/spell-it-out-screen-reader.de.mdx" /* webpackChunkName: "component---src-posts-2022-spell-it-out-screen-reader-de-mdx" */),
  "component---src-posts-2022-spell-it-out-screen-reader-en-mdx": () => import("./../../../src/posts/2022/spell-it-out-screen-reader.en.mdx" /* webpackChunkName: "component---src-posts-2022-spell-it-out-screen-reader-en-mdx" */),
  "component---src-posts-2022-styling-accessible-web-forms-de-mdx": () => import("./../../../src/posts/2022/styling-accessible-web-forms.de.mdx" /* webpackChunkName: "component---src-posts-2022-styling-accessible-web-forms-de-mdx" */),
  "component---src-posts-2022-styling-accessible-web-forms-en-mdx": () => import("./../../../src/posts/2022/styling-accessible-web-forms.en.mdx" /* webpackChunkName: "component---src-posts-2022-styling-accessible-web-forms-en-mdx" */),
  "component---src-posts-2023-accessible-news-carousel-de-mdx": () => import("./../../../src/posts/2023/accessible-news-carousel.de.mdx" /* webpackChunkName: "component---src-posts-2023-accessible-news-carousel-de-mdx" */),
  "component---src-posts-2023-accessible-news-carousel-en-mdx": () => import("./../../../src/posts/2023/accessible-news-carousel.en.mdx" /* webpackChunkName: "component---src-posts-2023-accessible-news-carousel-en-mdx" */),
  "component---src-posts-2023-accessible-ui-component-libraries-de-mdx": () => import("./../../../src/posts/2023/accessible-ui-component-libraries.de.mdx" /* webpackChunkName: "component---src-posts-2023-accessible-ui-component-libraries-de-mdx" */),
  "component---src-posts-2023-accessible-ui-component-libraries-en-mdx": () => import("./../../../src/posts/2023/accessible-ui-component-libraries.en.mdx" /* webpackChunkName: "component---src-posts-2023-accessible-ui-component-libraries-en-mdx" */),
  "component---src-posts-2023-aligned-layouts-with-css-subgrid-de-mdx": () => import("./../../../src/posts/2023/aligned-layouts-with-css-subgrid.de.mdx" /* webpackChunkName: "component---src-posts-2023-aligned-layouts-with-css-subgrid-de-mdx" */),
  "component---src-posts-2023-aligned-layouts-with-css-subgrid-en-mdx": () => import("./../../../src/posts/2023/aligned-layouts-with-css-subgrid.en.mdx" /* webpackChunkName: "component---src-posts-2023-aligned-layouts-with-css-subgrid-en-mdx" */),
  "component---src-posts-2023-description-list-html-element-de-mdx": () => import("./../../../src/posts/2023/description-list-html-element.de.mdx" /* webpackChunkName: "component---src-posts-2023-description-list-html-element-de-mdx" */),
  "component---src-posts-2023-description-list-html-element-en-mdx": () => import("./../../../src/posts/2023/description-list-html-element.en.mdx" /* webpackChunkName: "component---src-posts-2023-description-list-html-element-en-mdx" */),
  "component---src-posts-2023-how-to-meet-wcag-2-2-de-mdx": () => import("./../../../src/posts/2023/how-to-meet-wcag-2-2.de.mdx" /* webpackChunkName: "component---src-posts-2023-how-to-meet-wcag-2-2-de-mdx" */),
  "component---src-posts-2023-how-to-meet-wcag-2-2-en-mdx": () => import("./../../../src/posts/2023/how-to-meet-wcag-2-2.en.mdx" /* webpackChunkName: "component---src-posts-2023-how-to-meet-wcag-2-2-en-mdx" */),
  "component---src-posts-2023-inert-attribute-prevents-interaction-de-mdx": () => import("./../../../src/posts/2023/inert-attribute-prevents-interaction.de.mdx" /* webpackChunkName: "component---src-posts-2023-inert-attribute-prevents-interaction-de-mdx" */),
  "component---src-posts-2023-inert-attribute-prevents-interaction-en-mdx": () => import("./../../../src/posts/2023/inert-attribute-prevents-interaction.en.mdx" /* webpackChunkName: "component---src-posts-2023-inert-attribute-prevents-interaction-en-mdx" */),
  "component---src-posts-2023-pdf-accessibility-checker-de-mdx": () => import("./../../../src/posts/2023/pdf-accessibility-checker.de.mdx" /* webpackChunkName: "component---src-posts-2023-pdf-accessibility-checker-de-mdx" */),
  "component---src-posts-2023-pdf-accessibility-checker-en-mdx": () => import("./../../../src/posts/2023/pdf-accessibility-checker.en.mdx" /* webpackChunkName: "component---src-posts-2023-pdf-accessibility-checker-en-mdx" */),
  "component---src-posts-2023-popover-api-accessibility-de-mdx": () => import("./../../../src/posts/2023/popover-api-accessibility.de.mdx" /* webpackChunkName: "component---src-posts-2023-popover-api-accessibility-de-mdx" */),
  "component---src-posts-2023-popover-api-accessibility-en-mdx": () => import("./../../../src/posts/2023/popover-api-accessibility.en.mdx" /* webpackChunkName: "component---src-posts-2023-popover-api-accessibility-en-mdx" */),
  "component---src-posts-2023-pro-accessibility-certifications-de-mdx": () => import("./../../../src/posts/2023/pro-accessibility-certifications.de.mdx" /* webpackChunkName: "component---src-posts-2023-pro-accessibility-certifications-de-mdx" */),
  "component---src-posts-2023-pro-accessibility-certifications-en-mdx": () => import("./../../../src/posts/2023/pro-accessibility-certifications.en.mdx" /* webpackChunkName: "component---src-posts-2023-pro-accessibility-certifications-en-mdx" */),
  "component---src-posts-2023-responsive-design-container-queries-de-mdx": () => import("./../../../src/posts/2023/responsive-design-container-queries.de.mdx" /* webpackChunkName: "component---src-posts-2023-responsive-design-container-queries-de-mdx" */),
  "component---src-posts-2023-responsive-design-container-queries-en-mdx": () => import("./../../../src/posts/2023/responsive-design-container-queries.en.mdx" /* webpackChunkName: "component---src-posts-2023-responsive-design-container-queries-en-mdx" */),
  "component---src-posts-2023-rxjs-concurrent-request-sequence-de-mdx": () => import("./../../../src/posts/2023/rxjs-concurrent-request-sequence.de.mdx" /* webpackChunkName: "component---src-posts-2023-rxjs-concurrent-request-sequence-de-mdx" */),
  "component---src-posts-2023-rxjs-concurrent-request-sequence-en-mdx": () => import("./../../../src/posts/2023/rxjs-concurrent-request-sequence.en.mdx" /* webpackChunkName: "component---src-posts-2023-rxjs-concurrent-request-sequence-en-mdx" */),
  "component---src-posts-2023-unleash-power-of-inline-svg-de-mdx": () => import("./../../../src/posts/2023/unleash-power-of-inline-svg.de.mdx" /* webpackChunkName: "component---src-posts-2023-unleash-power-of-inline-svg-de-mdx" */),
  "component---src-posts-2023-unleash-power-of-inline-svg-en-mdx": () => import("./../../../src/posts/2023/unleash-power-of-inline-svg.en.mdx" /* webpackChunkName: "component---src-posts-2023-unleash-power-of-inline-svg-en-mdx" */),
  "component---src-posts-2024-accessible-popover-alert-de-mdx": () => import("./../../../src/posts/2024/accessible-popover-alert.de.mdx" /* webpackChunkName: "component---src-posts-2024-accessible-popover-alert-de-mdx" */),
  "component---src-posts-2024-accessible-popover-alert-en-mdx": () => import("./../../../src/posts/2024/accessible-popover-alert.en.mdx" /* webpackChunkName: "component---src-posts-2024-accessible-popover-alert-en-mdx" */),
  "component---src-posts-2024-css-scope-view-encapsulation-de-mdx": () => import("./../../../src/posts/2024/css-scope-view-encapsulation.de.mdx" /* webpackChunkName: "component---src-posts-2024-css-scope-view-encapsulation-de-mdx" */),
  "component---src-posts-2024-css-scope-view-encapsulation-en-mdx": () => import("./../../../src/posts/2024/css-scope-view-encapsulation.en.mdx" /* webpackChunkName: "component---src-posts-2024-css-scope-view-encapsulation-en-mdx" */),
  "component---src-posts-2024-design-a-11-y-annotations-de-mdx": () => import("./../../../src/posts/2024/design-a11y-annotations.de.mdx" /* webpackChunkName: "component---src-posts-2024-design-a-11-y-annotations-de-mdx" */),
  "component---src-posts-2024-design-a-11-y-annotations-en-mdx": () => import("./../../../src/posts/2024/design-a11y-annotations.en.mdx" /* webpackChunkName: "component---src-posts-2024-design-a-11-y-annotations-en-mdx" */),
  "component---src-posts-2024-native-dialog-and-popover-de-mdx": () => import("./../../../src/posts/2024/native-dialog-and-popover.de.mdx" /* webpackChunkName: "component---src-posts-2024-native-dialog-and-popover-de-mdx" */),
  "component---src-posts-2024-native-dialog-and-popover-en-mdx": () => import("./../../../src/posts/2024/native-dialog-and-popover.en.mdx" /* webpackChunkName: "component---src-posts-2024-native-dialog-and-popover-en-mdx" */),
  "component---src-posts-2024-two-color-focus-indicator-de-mdx": () => import("./../../../src/posts/2024/two-color-focus-indicator.de.mdx" /* webpackChunkName: "component---src-posts-2024-two-color-focus-indicator-de-mdx" */),
  "component---src-posts-2024-two-color-focus-indicator-en-mdx": () => import("./../../../src/posts/2024/two-color-focus-indicator.en.mdx" /* webpackChunkName: "component---src-posts-2024-two-color-focus-indicator-en-mdx" */),
  "component---src-templates-post-list-template-tsx": () => import("./../../../src/templates/post-list-template.tsx" /* webpackChunkName: "component---src-templates-post-list-template-tsx" */)
}

